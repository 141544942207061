import React from "react";
import cx from "classnames";
import { Meta, Text } from "../../Type";
import { useEventDetailContext } from "../../../contexts/eventDetailProvider";
import { useAuth } from "../../../contexts/AuthProvider";
import { coordWeldMap } from "../../../helpers/coordWeldMap";
import {
  MILLISECONDS_IN_SECONDS,
  METERS_IN_KM
} from "../../../helpers/constants";
import styles from "./index.module.scss";
import DateTime from "../../DateTime";
import { kpToMp } from "../../AnalysisCharts/BaseChart";

const EventInfo = () => {
  const { event } = useEventDetailContext();
  const { permissions } = useAuth();

  let estimatedArrival;
  let coordinatesString = "";
  if (event) {
    const nextPoi = event.eventMeta && event.eventMeta.nextPoi;
    const speed = event.eventMeta && event.eventMeta.speed;
    const timestamp: number | undefined = event.eventMeta
      ? event.eventMeta.timestamp
      : undefined;
    if (nextPoi && speed && timestamp) {
      const distanceInKm = nextPoi.post - event.post;
      const secondsToArrival = Math.floor(
        (distanceInKm * METERS_IN_KM) / speed
      );
      estimatedArrival = timestamp + secondsToArrival;
    }
    const COORDINATE_DIGITS = 6;
    coordinatesString = `${event.coordinates.latitude.toFixed(
      COORDINATE_DIGITS
    )},${event.coordinates.longitude.toFixed(COORDINATE_DIGITS)}`;
  }

  return event ? (
    <div className={styles.Wrapper}>
      <div className={styles.Row}>
        <Meta component="h5" size="small" dark>
          {event.type === "pig" || event.type === "train"
            ? "RUN ID"
            : "pipeline"}
        </Meta>
        <Text className={styles.Text} component="span" size="small">
          {event.type === "train" && event.eventMeta
            ? event.eventMeta.railId
            : event.pipeline.name}
          {(event.type === "pig" || event.type === "train") && event.eventMeta
            ? ` - Run ID ${event.eventMeta.runId}`
            : ""}
        </Text>
      </div>
      <div className={styles.Row}>
        <Meta component="h5" size="small" dark>
          {permissions.user.imperial ? "Mile Post" : "KM Post"}
        </Meta>
        {event && (
          <Text className={styles.Text} component="span" size="small">
            {permissions.user.imperial
              ? `MP ${kpToMp(event.post)}`
              : `KP ${event.post}`}
          </Text>
        )}
      </div>
      {event.pipeline.id === "suncor_icp" ? (
        <div className={styles.Row}>
          <Meta component="h5" size="small" dark>
            Weld ID
          </Meta>
          {event && (
            <Text className={styles.Text} component="span" size="small">
              {coordWeldMap(coordinatesString)}
            </Text>
          )}
        </div>
      ) : (
        undefined
      )}
      <div className={styles.Row}>
        <Meta component="h5" size="small" dark>
          Start Time
        </Meta>
        {event && (
          <Text className={styles.Text} component="span" size="small">
            {event.startTime ? (
              <DateTime
                timestamp={event.startTime * MILLISECONDS_IN_SECONDS}
                length="long"
              />
            ) : (
              "-"
            )}
          </Text>
        )}
      </div>
      <div className={styles.Row}>
        <Meta component="h5" size="small" dark>
          End Time
        </Meta>
        {event && (
          <Text className={styles.Text} component="span" size="small">
            {event.endTime ? (
              <DateTime
                timestamp={event.endTime * MILLISECONDS_IN_SECONDS}
                length="long"
              />
            ) : (
              "-"
            )}
          </Text>
        )}
      </div>
      <div className={styles.Row}>
        <Meta component="h5" size="small" dark>
          Archived
        </Meta>
        {event && (
          <Text className={styles.Text} component="span" size="small">
            {event.archived && event.archivedDate ? (
              <DateTime
                timestamp={event.archivedDate * MILLISECONDS_IN_SECONDS}
                length="long"
              />
            ) : (
              "-"
            )}
          </Text>
        )}
      </div>
      <div className={styles.Row}>
        <Meta component="h5" size="small" dark>
          Lat/Lng
        </Meta>
        {event && (
          <a
            href={`https://maps.google.com/maps?q=loc:${event.coordinates.latitude},${event.coordinates.longitude}`}
            className={styles.Link}
            target="__blank"
          >
            <Text className={styles.Text} component="span" size="small">
              {`${event.coordinates.latitude}, ${event.coordinates.longitude}`}
            </Text>
          </a>
        )}
      </div>
      {event.type === "pig" && event.eventMeta ? (
        <>
          <hr className={styles.Divider} />
          <div className={styles.Row}>
            <Meta component="h5" size="small" dark>
              Speed
            </Meta>
            <Text className={styles.Text} component="span" size="small">
              {event.eventMeta.speed}m/second
            </Text>
          </div>
          <div className={styles.Row}>
            <Meta component="h5" size="small" dark>
              Next POI
            </Meta>
            <Text className={styles.TextPoi} component="span" size="small">
              {event.eventMeta.nextPoi
                ? `${event.eventMeta.nextPoi.type} ${event.eventMeta.nextPoi.post}`
                : "N/A"}
            </Text>
          </div>
          {estimatedArrival && (
            <div className={styles.Row}>
              <Meta component="h5" size="small" dark>
                Arrival (EST.)
              </Meta>
              <Text className={styles.Text} component="span" size="small">
                <DateTime
                  timestamp={estimatedArrival * MILLISECONDS_IN_SECONDS}
                  length="long"
                />
              </Text>
            </div>
          )}
        </>
      ) : permissions.user["view-admin-event"] ? (
        <>
          <hr className={styles.Divider} />
          <div className={styles.Row}>
            <Meta component="h5" size="small" dark>
              Channel
            </Meta>
            {event && (
              <Text className={styles.Text} component="span" size="small">
                {event && event.channel ? event.channel : "-"}
              </Text>
            )}
          </div>
          <div className={styles.Row}>
            <Meta component="h5" size="small" dark>
              Guid
            </Meta>
            <Text
              className={cx(styles.Text, styles.Wrap)}
              component="span"
              size="small"
              disambiguation
            >
              {event ? event.id.toUpperCase() : "-"}
            </Text>
          </div>
        </>
      ) : null}
    </div>
  ) : null;
};

export default EventInfo;
