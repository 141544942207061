import React, { useEffect, useState } from "react";
import { gqlType } from "@hifieng/common";
import ScreenLayout from "../ScreenLayout";
import { useOrganizationContext } from "../../contexts/OrganizationProvider";
import Loading from "../Loading";
import FilterContentsSection from "../FilterContentsSection";
import AnalysisFilters from "../AnalysisFilters";
import MobilePageTitle from "../MobilePageTitle";
import EventMap from "../EventMap";
import { getPipelineChKpMap } from "../../helpers/analysisFunctions";
import { SearchRange } from "../../types/AnalysisTypes";

const AnalysisPage = () => {
  const { activeOrg } = useOrganizationContext();

  const [pipelines, setPipelines] = useState<Array<gqlType.Pipeline>>();
  const [pipeline, setPipeline] = useState<gqlType.Pipeline>();
  const [fiber, setFiber] = useState<gqlType.ChannelKPostMap>();
  const [KPRange, setKPRange] = useState<SearchRange>();

  useEffect(() => {
    if (activeOrg && activeOrg.pipelines) {
      setPipelines((activeOrg && activeOrg.pipelines) || []);
      setPipeline(activeOrg.pipelines[0]);
      setFiber(getPipelineChKpMap(activeOrg.pipelines[0]));
    }
  }, [activeOrg]);

  if (pipelines && pipelines.length && pipeline && fiber) {
    return (
      <ScreenLayout title="Analysis">
        <FilterContentsSection
          headerComponent={<MobilePageTitle page="Analysis Tool" />}
          filterComponent={
            <AnalysisFilters
              pipelines={pipelines}
              fiber={fiber}
              pipeline={pipeline}
              onPipelineChange={newPipeline => {
                setPipeline(newPipeline);
                setFiber(getPipelineChKpMap(newPipeline));
              }}
              onFiberChange={newFiber => {
                setFiber(newFiber);
              }}
              setMapRange={setKPRange}
              KPRange={KPRange}
            />
          }
          contentComponent={
            <EventMap
              focusPipelines={
                pipeline
                  ? [pipeline.id]
                  : pipelines.length === 1
                  ? [pipelines[0].id]
                  : undefined
              }
              canSearchOnMapMove={false}
              events={[]}
              scrollZoom
              circledRange={KPRange}
            />
          }
        />
      </ScreenLayout>
    );
  }

  return <Loading />;
};

export default AnalysisPage;
