import React, {
  useState,
  useContext,
  useCallback,
  useEffect,
  useMemo
} from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { gqlType } from "@hifieng/common";
import AppError from "../errors/AppError";
import { IOrganizations } from "../types/OrganizationTypes";
import useDeepCompareEffect from "use-deep-compare-effect";
import moment from "moment-timezone";

export const GET_ORGANIZATIONS_DATA = gql`
  query {
    organizations {
      id
      name
      imageUrl
      utcOffset
      timezoneAbbr
      pipelines {
        id
        name
        color
        segments {
          id
          post
          coordinates {
            latitude
            longitude
          }
        }
        assets {
          id
          type
          post
          coordinates {
            latitude
            longitude
          }
        }
        kmPosts {
          post
          coordinates {
            latitude
            longitude
          }
        }
        interfaceOptions {
          hideFromMapLegend
          hideHeartbeat
          initialFocus
        }
        chKpMaps {
          id
          name
          ch
          kp
          precision
          step
        }
      }
    }
  }
`;

export type OrganizationResponseDataType = {
  id: string;
  name: string;
  imageUrl: string;
  pipelines: Array<gqlType.Pipeline>;
  pointsOfInterest: Array<gqlType.PointOfInterest>;
};

const initialContext: IOrganizations = {
  activeOrg: undefined,
  setActiveOrg: () => {
    throw new Error("Organization context has not yet been initialized.");
  },
  organizations: undefined
};

type PropsType = {
  children: React.ReactNode;
};

const daylightSavingsOrganizations = [
  "transmountain_security",
  "transmountain_hope",
  "transmountain_merritt",
  "hifi-demo",
  "hifi-demo-2",
  "hifi-test",
  "hifi"
];
const timezoneAbbrEndIdx = 2;
const applyDaylightSavings = (org?: gqlType.Organization) => {
  if (org !== undefined) {
    // check if current datetime is within daylight savings. apply change if required
    const globalDST = moment.tz("America/Edmonton").isDST();
    if (globalDST === true) {
      org.timezoneAbbr = `${org.timezoneAbbr[0]}D${org.timezoneAbbr[timezoneAbbrEndIdx]}`;
      const utcOffsetNumber = parseFloat(org.utcOffset) + 1;
      const startChar = utcOffsetNumber < 0 ? "-" : "+";
      org.utcOffset = `${startChar}0${Math.abs(utcOffsetNumber)}:00`;
    }
  }
};

const findOrgById = (
  orgId: string,
  organizations?: Array<gqlType.Organization>,
  enableDst?: boolean
) => {
  const activeOrg =
    organizations && organizations.find(org => org.id === orgId);

  // auto daylight savings for tmx security deployments
  if (daylightSavingsOrganizations.includes(orgId) && enableDst === true) {
    applyDaylightSavings(activeOrg);
  }

  return activeOrg;
};

export const OrganizationContext = React.createContext(initialContext);
export const useOrganizationContext = () => useContext(OrganizationContext);
export const OrganizationProvider = (props: PropsType) => {
  const { error, loading, data } = useQuery<IOrganizations>(
    GET_ORGANIZATIONS_DATA
  );
  const [activeOrgId, setActiveOrgId] = useState<string>();
  const [organizations, setOrganizations] = useState<
    Array<gqlType.Organization>
  >();

  if (error) {
    throw new AppError(
      "Organization information could not be retrieved",
      error.message
    );
  }

  if (!data && !loading) {
    throw new AppError(
      "Organization data could not be fetched",
      "Refresh the page to try again. If this problem persists, contact Hifi support."
    );
  }

  // The function that allows other parts of the app to change the organization
  // useCallback is used so that the components that implement this context don't render every time
  // this provider re-renders.
  const setActiveOrg = useCallback(
    (orgId: string) => {
      if (!organizations) {
        throw new Error(
          "You can't switch organizations while the application is loading"
        );
      }
      if (!findOrgById(orgId, organizations)) {
        throw new AppError(
          `Cannot switch to Organization "${orgId}" because it does not exist`,
          "Please refresh your browser and try again."
        );
      }

      setActiveOrgId(orgId);
      localStorage.setItem("hifi:activeOrg", orgId);
    },
    [organizations]
  );

  // useQuery has an internal caching mechanism that causes `data` to update more often than
  // necessary. The LiveStatus polling queries cause this provider to update as well. Doing a deep
  // comparison is a good way to check if the organizations have actually changed.
  useDeepCompareEffect(() => {
    if (data) {
      setOrganizations(data.organizations);
    }
  }, [data || {}]);

  // This should only run on application startup when there is no active org ID yet.
  // If the user does not have an active org defined, grab it from localhost, or fall back to
  // picking the first one.
  useEffect(() => {
    if (organizations && !activeOrgId) {
      // User must belong to at least one org
      if (organizations.length === 0) {
        throw new AppError(
          "Your account is not associated with any organizations",
          "Contact Hifi support to resolve this issue."
        );
      }

      const previouslyActiveOrgId = localStorage.getItem("hifi:activeOrg");
      if (
        previouslyActiveOrgId &&
        findOrgById(previouslyActiveOrgId, organizations)
      ) {
        // If an org was previously active, restore it
        setActiveOrgId(previouslyActiveOrgId);
      } else {
        // Otherwise, just pick the first org
        setActiveOrgId(organizations[0].id);
      }
    }
  }, [activeOrgId, organizations]);

  // Memoize the provider value, otherwise on every re-render the value will change triggering
  // re-renders throughout the app. If memo isn't used, the post markers on the events map will
  // flicker due to organization "changing" on every re-render.
  const providerValue = useMemo(() => {
    if (!organizations || !activeOrgId) return initialContext;

    return {
      setActiveOrg,
      organizations,
      activeOrg: findOrgById(activeOrgId, organizations, true)
    };
  }, [setActiveOrg, organizations, activeOrgId]);

  return (
    <OrganizationContext.Provider value={providerValue}>
      {props.children}
    </OrganizationContext.Provider>
  );
};
